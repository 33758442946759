import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // 引入一份进度条插件
import 'nprogress/nprogress.css' // 引入进度条样式
import constantRoutes from "./routerConfig";
import store from "../store";
// 注册路由并使用
Vue.use(VueRouter)
const createRouter = () => new VueRouter({
    //将默认的hash模式修改为history模式 hash模式在url中自带 # 符号 history没有
    mode: 'history',
    // 当从当前路由切换/之间前往新地址的时候，页面自动滚到顶部或者保持原本的位置，
    // 并解决vue框架页面跳转有白色不可追踪色块的bug
    scrollBehavior: () => ({
        y: 0
    }),
    //配置静态路由与组件之间的映射关系
    routes: constantRoutes
})

// 定义常量【router】接收 【createRouter】并转成一个初始化/实例化路由对象
const router = createRouter()

const whileList = ['/login', '404'] // 定义白名单，自选不受权限控制的页面
// 导航的前置守卫
router.beforeEach((to, from, next) => {
    // 在导航守卫一开始就开启进度条
    NProgress.start()
    if (store.state.common.token) {
        //   如果本地vuex缓存有token 继续判断是不是去登录页
        // if (to.path === '/login' || to.path === '/') {
        if (to.path === '/login' || to.path === '/njfblogin' || to.path === '/') {
            //  表示去的是登录页
            // next('/index') // 跳到主页
            next()
        } else {
            // 存储当前的路径信息
            store.dispatch('common/setActivePath',to.path)
            // 直接放行，主页停留
            next()
        }
    } else {
        //   如果没有token
        if (whileList.indexOf(to.path) > -1) {
            // 表示要去的地址在白名单里
            next()
        } else {
            next('/login') // 跳到登录页
            NProgress.done()
        }
    }
    // 手动强制关闭一次  为了解决 手动切换地址时  进度条的不关闭的问题
    NProgress.done()
})

// 导航的后置守卫
router.afterEach(() => {
    //关闭进度条
    NProgress.done()
})

export default router
