const state = {
    equipInfo:{
        equipNo:'',
        equipTypeId:'',
        orgaIdArr:[],
        equipSource:'',
        annexUrl:[]
    }
}
// 修改state状态必须通过mutations对象
const mutations = {
    SETEQUIPINFO(state,data){
        state.equipInfo = data
    }
}
const actions ={
    setEquipInfo(context,param){
        // 存储进场验收的信息
        context.commit('SETEQUIPINFO', param)
    },
}
// 对外暴露/导出设置左侧菜单折叠的模块
export default {
    // 高封闭，加锁，路径安全性
    namespaced: true,
    state,
    mutations,
    actions
}