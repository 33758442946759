// 状态
const state = {
    token:'',
    user:{
        orgaId:'',  // 机构id
        projId:'',  // 项目 id
        staffId:'', // 人员 id
        staffName:'', // 名称
        staffSex:'', // 性别
        staffStatus:'', // 状态
        staffType: '',// 类型
        orgaPathIds: '', // 组织机构路径
        orgaLevel: 0 , // 组织机构层级 0 集团 1 公司 2  项目
    },
    staffRole: [], // 菜单
    activePath:''

}
// 修改state状态必须通过mutations对象
const mutations = {
    SETLOGINUSERINFO(state,data) {
        let {token,orgaId,projId,staffId,staffName,staffSex,staffStatus,staffType,staffRole,orgaLevel,compAlias,compIcon} = data
        state.token = token
        if(staffRole && staffRole.length > 0){
            // 处理左侧菜单的展示规则
            let staffRoleLIst = staffRole.sort((a,b)=>a.moduSort - b.moduSort).map(item =>{
                let get = (data) =>{
                    return  data.sort((a,b)=>a.moduSort - b.moduSort).map(child =>{
                        return {
                            meta:{
                                title: child.moduName,
                                icon: child.moduIcon || 'el-icon-s-tools'
                            },
                            path: child.moduUrl,
                            fullPath: child.moduUrl,
                            children: child.children && child.children.length >0 ? get(child.children): null
                        }
                    })
                }
                return {
                    meta:{
                        title: item.moduName,
                        icon: item.moduIcon || 'el-icon-s-tools'
                    },
                    path: item.moduUrl,
                    fullPath: item.moduUrl,
                    children: get(item.children)
                }
            })
            state.staffRole = staffRoleLIst
        }else {
            state.staffRole = []
        }
        // 人员信息
        state.user = {orgaId,projId,staffId,staffName,staffSex,staffStatus,staffType,orgaLevel,compAlias,compIcon}
    },
    SETACTIVEPATH(state, data){
        state.activePath = data
    },
    SETUSERINFO(state,data){
        state.user.orgaPathIds = data.orgaPathIds
        let len = data.orgaPathIds.split('-')
        state.user.isProject = len.length > 2
    }

}
const actions ={
    setLoginUserInfo(context,param){
        context.commit('SETLOGINUSERINFO', param)
    },
    setActivePath(context,param){
        let path = param.split('?')[0]
        context.commit('SETACTIVEPATH', path)
    },
    setUserInfo(context,param){
        context.commit('SETUSERINFO', param)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}