// 左侧导航菜单栏折叠状态管理模块
// 状态
const state = {
    isCollapse: false,
    activePath: ''
}
// 修改state状态必须通过mutations对象
const mutations = {
    collapseMenu(state) {
        state.isCollapse = !state.isCollapse
    },
    SETACTIVEPATH(state,data){
        state.activePath = data
    }
}
const actions ={
    setActivePath(context,param){
        context.commit('SETACTIVEPATH', param)
    },
}
// 对外暴露/导出设置左侧菜单折叠的模块
export default {
    // 高封闭，加锁，路径安全性
    namespaced: true,
    state,
    mutations,
    actions
}