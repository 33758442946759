import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui'
import BaiduMap from 'vue-baidu-map'
//样式文件
// 引入elementui样式
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.less'

Vue.use(ElementUI)
Vue.use(BaiduMap,{
  ak:'CUAPcdlTSGvsv5bzMyGHEvT6tgTMMact'
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
