// 用户信息模块
// 状态
const state = {
    roleCheckbox: {},
    roleCheckboxList:[]

}
// 修改state状态必须通过mutations对象，只能写同步方法
const mutations = {
    SETCHECKMENU(state,data){
        state.roleCheckbox[data.moduId] = {
            children: data.children,
            checked: data.checked,
            moduId: data.moduId
        }
        let roleCheckboxList = []
        for(let key in state.roleCheckbox){
            roleCheckboxList.push(state.roleCheckbox[key])
        }
        state.roleCheckboxList = roleCheckboxList
        // if(state.roleCheckboxList.length >1){
        //     if(state.roleCheckboxList.find(item => item.moduId == data.moduId)){
        //
        //     }
        // }else {
        //     state.roleCheckboxList.push(data)
        // }
    },
    CLEARCHECKMENU(state,data){
        state.roleCheckbox = {}
        state.roleCheckboxList = []
    }
}
const actions ={
    setCheckMenu(context,param){
        context.commit('SETCHECKMENU', param)
    },
    clearCheckMenu(context,param){
        context.commit('CLEARCHECKMENU',param)
    }
}

// 对外暴露/导出设置用户信息的模块
export default {
    // 高封闭，加锁，路径安全性
    namespaced: true,
    state,
    mutations,
    actions
}