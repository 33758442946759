const constantRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/view',
        name: 'view',
        redirect: '/view/basic/company',
        component: () => import('@/view/layout/index'),
        children: [
            {
                path: 'person',
                name: 'person',
                fullPath: '/view/person',
                component: () => import('@/view/pages/person/person'),
                meta: {
                    title: '个人信息',
                    icon: 'el-icon-s-tools'
                },
                rightId: '0',
            }, {
                path: 'basic',
                name: 'basic',
                fullPath: '/view/basic',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '基础数据',
                    icon: 'el-icon-s-tools'
                },
                rightId: '0',
                children: [
                    {
                        path: 'company',
                        name: 'company',
                        fullPath: '/view/basic/company',
                        component: () => import('@/view/pages/basic/company'),
                        meta: {
                            title: '公司信息',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    }, {
                        path: 'project',
                        name: 'project',
                        fullPath: '/view/basic/project',
                        component: () => import('@/view/pages/basic/project'),
                        meta: {
                            title: '项目信息',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    }, {
                        path: 'staff',
                        name: 'staff',
                        fullPath: '/view/basic/staff',
                        component: () => import('@/view/pages/basic/staff'),
                        meta: {
                            title: '管理人员',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    }, {
                        path: 'operator',
                        name: 'operator',
                        fullPath: '/view/basic/operator',
                        component: () => import('@/view/pages/basic/operator'),
                        meta: {
                            title: '操作人员',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    }, {
                        path: 'sysConfig',
                        name: 'sysConfig',
                        fullPath: '/view/basic/sysConfig',
                        component: () => import('@/view/pages/basic/sysConfig'),
                        meta: {
                            title: '系统设置',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    }, {
                        path: 'smsLogs',
                        name: 'smsLogs',
                        fullPath: '/view/basic/smsLogs',
                        component: () => import('@/view/pages/basic/smsLogs'),
                        meta: {
                            title: '短信记录',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    },
                    {
                        path: 'userReport',
                        name: 'userReport',
                        fullPath: '/view/basic/userReport',
                        component: () => import('@/view/pages/basic/userReport'),
                        meta: {
                            title: '人员报道',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1'
                    },
                ]
            }, {
                path: 'accommodation',
                name: 'accommodation',
                fullPath: '/view/accommodation',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '住宿管理',
                    icon: 'el-icon-s-tools'
                },
                rightId: '1',
                children: [
                    {
                        path: 'hotelManage',
                        name: 'hotelManage',
                        fullPath: '/view/accommodation/hotelManage',
                        component: () => import('@/view/pages/accommodation/hotelManage'),
                        meta: {
                            title: '酒店管理',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },
                    {
                        path: 'roomManage',
                        name: 'roomManage',
                        fullPath: '/view/accommodation/roomManage',
                        component: () => import('@/view/pages/accommodation/roomManage'),
                        meta: {
                            title: '房间管理',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },
                    {
                        path: 'roomReservation',
                        name: 'roomReservation',
                        fullPath: '/view/accommodation/roomReservation',
                        component: () => import('@/view/pages/accommodation/roomReservation'),
                        meta: {
                            title: '房间预留',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },{
                        path: 'roomAssignment',
                        name: 'roomAssignment',
                        fullPath: '/view/accommodation/roomAssignment',
                        component: () => import('@/view/pages/accommodation/roomAssignment'),
                        meta: {
                            title: '房间分配',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },
                ]
            }, {
                path: 'meeting',
                name: 'meeting',
                fullPath: '/view/meeting',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '会议管理',
                    icon: 'el-icon-s-tools'
                },
                rightId: '1',
                children: [
                    {
                        path: 'venueManage',
                        name: 'venueManage',
                        fullPath: '/view/meeting/venueManage',
                        component: () => import('@/view/pages/meeting/venueManage'),
                        meta: {
                            title: '会场管理',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },{
                        path: 'meetingMange',
                        name: 'meetingMange',
                        fullPath: '/view/meeting/meetingMange',
                        component: () => import('@/view/pages/meeting/meetingMange'),
                        meta: {
                            title: '会议管理',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },{
                        path: 'meetingMinutes',
                        name: 'meetingMinutes',
                        fullPath: '/view/meeting/meetingMinutes',
                        component: () => import('@/view/pages/meeting/meetingMinutes'),
                        meta: {
                            title: '会议纪要',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },{
                        path: 'activityCollection',
                        name: 'activityCollection',
                        fullPath: '/view/meeting/activityCollection',
                        component: () => import('@/view/pages/meeting/activityCollection'),
                        meta: {
                            title: '活动锦集',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    },
                ]
            }, {
                path: 'orderManage',
                name: 'orderManage',
                fullPath: '/view/orderManage',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '订单管理',
                    icon: 'el-icon-s-tools'
                },
                rightId: '1',
                children: [
                    {
                        path: 'orderInfo',
                        name: 'orderInfo',
                        fullPath: '/view/orderManage/orderInfo',
                        component: () => import('@/view/pages/orderManage/orderInfo'),
                        meta: {
                            title: '订单信息',
                            icon: 'el-icon-s-tools'
                        },
                        rightId: '1',
                    }
                ]
            },
            {
                path: 'rights',
                name: 'rights',
                fullPath: '/view/rights',
                component: () => import('@/view/layout/layoutView'),
                meta: {
                    title: '权限管理',
                    icon: 'el-icon-s-tools'
                },
                rightId: '0',
                children: [{
                    path: 'role',
                    name: 'role',
                    fullPath: '/view/rights/role',
                    component: () => import('@/view/pages/rights/role'),
                    meta: {
                        title: '角色管理',
                        icon: 'el-icon-s-tools'
                    },
                    rightId: '1'
                }, {
                    path: 'rolePermission',
                    name: 'rolePermission',
                    fullPath: '/view/rights/rolePermission',
                    component: () => import('@/view/pages/rights/rolePermission'),
                    meta: {
                        title: '角色权限',
                        icon: 'el-icon-s-tools'
                    },
                    rightId: '1'
                }, {
                    path: 'staffRole',
                    name: 'staffRole',
                    fullPath: '/view/rights/staffRole',
                    component: () => import('@/view/pages/rights/staffRole'),
                    meta: {
                        title: '用户角色',
                        icon: 'el-icon-s-tools'
                    },
                    rightId: '1'
                }, {
                    path: 'rightMenu',
                    name: 'rightMenu',
                    fullPath: '/view/rights/rightMenu',
                    component: () => import('@/view/pages/rights/rightMenu'),
                    meta: {
                        title: '菜单管理',
                        icon: 'el-icon-s-tools'
                    },
                    rightId: '1'
                }, {
                    path: 'dataDictionary',
                    name: 'dataDictionary',
                    fullPath: '/view/rights/dataDictionary',
                    component: () => import('@/view/pages/rights/dataDictionary'),
                    meta: {
                        title: '数据字典',
                        icon: 'el-icon-s-tools'
                    },
                    rightId: '1'
                }]
            }

            // {
            //     path: 'record',
            //     name: 'record',
            //     fullPath: '/view/record',
            //     component: () => import('@/view/layout/layoutView'),
            //     meta:{
            //         title: '设备管理',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            //     children:[{
            //         path: 'acceptance',
            //         name: 'acceptance',
            //         fullPath: '/view/record/acceptance',
            //         component: () => import('@/view/pages/record/acceptance'),
            //         meta:{
            //             title: '进场验收',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '2'
            //     },{
            //         path: 'equipmentBind',
            //         name: 'equipmentBind',
            //         fullPath: '/view/record/equipmentBind',
            //         component: () => import('@/view/pages/record/equipmentBind'),
            //         meta:{
            //             title: '设备绑定',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '3'
            //     },{
            //         path: 'equipmentledger',
            //         name: 'equipmentledger',
            //         fullPath: '/view/record/equipmentledger',
            //         component: () => import('@/view/pages/record/equipmentledger'),
            //         props: () => ({ query: {} }),
            //         meta:{
            //             title: '设备台账',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '4'
            //     },{
            //         path: 'equipmentledgerZy',
            //         name: 'equipmentledgerZy',
            //         fullPath: '/view/record/equipmentledgerZy',
            //         component: () => import('@/view/pages/record/equipmentledger'),
            //         props: () => ({ query: {equipSource:'1'} }),
            //         meta:{
            //             title: '设备台账-自有',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '4'
            //     },{
            //         path: 'equipmentledgerWz',
            //         name: 'equipmentledgerWz',
            //         fullPath: '/view/record/equipmentledgerWz',
            //         component: () => import('@/view/pages/record/equipmentledger'),
            //         props: () => ({ query: {equipSource:'0'} }),
            //         meta:{
            //             title: '设备台账-外租',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '4'
            //     },{
            //         path: 'equipmentledgerDw',
            //         name: 'equipmentledgerDw',
            //         fullPath: '/view/record/equipmentledgerDw',
            //         component: () => import('@/view/pages/record/equipmentledger'),
            //         props: () => ({ query: {equipSource:'2'} }),
            //         meta:{
            //             title: '设备台账-队伍自带',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '4'
            //     },{
            //         path: 'equipmentledgerTz',
            //         name: 'equipmentledgerTz',
            //         fullPath: '/view/record/equipmentledgerTz',
            //         component: () => import('@/view/pages/record/equipmentledger'),
            //         props: () => ({ query: {equipTypeScreen:'1'} }),
            //         meta:{
            //             title: '设备台账-特种设备',
            //             icon: 'el-icon-s-tools'
            //         },
            //         rightId: '4'
            //     }]
            // },{
            //     path: 'equipmentDismantling',
            //     name: 'equipmentDismantling',
            //     fullPath: '/view/equipmentDismantling',
            //     component: () => import('@/view/pages/equipmentDismantling/equipmentDismantling'),
            //     meta:{
            //         title: '安拆台账',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'equipmentOperator',
            //     name: 'equipmentOperator',
            //     fullPath: '/view/equipmentOperator',
            //     component: () => import('@/view/pages/equipmentOperator/equipmentOperator'),
            //     meta:{
            //         title: '设备操作员',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'selfcheck',
            //     name: 'selfcheck',
            //     fullPath: '/view/selfcheck',
            //     component: () => import('@/view/pages/selfcheck/selfcheck'),
            //     meta:{
            //         title: '设备自检',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'onsiteInspection',
            //     name: 'onsiteInspection',
            //     fullPath: '/view/onsiteInspection',
            //     component: () => import('@/view/pages/onsiteInspection/onsiteInspection'),
            //     meta:{
            //         title: '设备巡检',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'monthInspection',
            //     name: 'monthInspection',
            //     fullPath: '/view/monthInspection',
            //     component: () => import('@/view/pages/monthInspection/monthInspection'),
            //     meta:{
            //         title: '设备月检',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'equipmentMaintenance',
            //     name: 'equipmentMaintenance',
            //     fullPath: '/view/equipmentMaintenance',
            //     component: () => import('@/view/pages/equipmentMaintenance/equipmentMaintenance.vue'),
            //     meta:{
            //         title: '维保记录',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'rectificationRecords',
            //     name: 'rectificationRecords',
            //     fullPath: '/view/rectificationRecords',
            //     component: () => import('@/view/pages/rectificationRecords/rectificationRecords.vue'),
            //     meta:{
            //         title: '整改记录',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },{
            //     path: 'alarmLedger',
            //     name: 'alarmLedger',
            //     fullPath: '/view/alarmLedger',
            //     component: () => import('@/view/pages/alarmLedger/alarmLedger.vue'),
            //     meta:{
            //         title: '告警台账',
            //         icon: 'el-icon-s-tools'
            //     },
            //     rightId: '0',
            // },

        ]
    }, {
        path: '/login',
        name: 'Index',
        // 路由懒加载，即进入该路由才会加载该路由，不会一进入网页就初始化所有路由，会造成客户端响应延迟
        component: () => import('@/view/login/index'),
        // hidden 代表该条路由不在菜单展示，一般为登录页，403,404页
        hidden: true
    }, {
        path: '/projectScreen',
        name: 'projectScreen',
        component: () => import('@/view/pages/screen/projectScreen'),
    }, {
        path: '/403',
        name: '403',
        component: () => import('@/view/error/page_403')
    }, {
        path: '*',
        name: '404',
        component: () => import('@/view/error/page_404')
    }
]
export default constantRoutes